<template>
  <div class="create" v-loading="loading">
    <el-page-header class="back-header" :content="title" title="返回" @back="setting('取消')"/>
    <el-form
      ref="form"
      :model="rawForm"
      :rules="rules"
      label-width="100px"
      hide-required-asterisk
    >
      <el-divider content-position="left"><h4>基本信息</h4></el-divider>
      <el-form-item label="比赛名称：" prop="name">
        <el-input
          class="normal-item"
          v-model="rawForm.name"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="主办方：" prop="sponsor">
        <el-input
          class="normal-item"
          v-model="rawForm.sponsor"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="电子邮箱：" prop="email">
        <el-input
          class="normal-item"
          v-model="rawForm.email"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="比赛介绍：" prop="details">
        <el-input
          type="textarea"
          v-model="rawForm.details"
          placeholder="请输入比赛介绍(长度限制253)"
		  oninput="if(value.length>253)value=value.slice(0,253)"
        ></el-input>
        <!-- oninput="if(value.length>255)value=value.slice(0,255)" -->
      </el-form-item>
      <el-divider content-position="left"><h4>比赛设置</h4></el-divider>
      <el-form-item label="大赛人数：" prop="number">
        <el-input
          :disabled="title==='设置'"
          class="normal-item"
          v-model="rawForm.number"
          placeholder="请输入大赛人数(最大支持9999)"
		  maxlength="4"
        >
          <template #append>人</template>
        </el-input>
      </el-form-item>
      <el-form-item label="比赛类型：" prop="matchType">
        <el-radio-group
          :disabled="title==='设置'" v-model="rawForm.matchType">
          <el-radio :label="0">单人</el-radio>
          <el-radio :label="1">组队</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="rawForm.matchType == 1"
        label="每组人数："
        prop="groupsNumber"
      >
        <el-input
          :disabled="title==='设置'"
          class="normal-item"
          v-model="rawForm.groupsNumber"
          placeholder="请输入"
        >
            <template #prepend>
      <el-select v-model="rawForm.numberType"
          :disabled="title==='设置'" style="width: 80px">
        <el-option value="至少"></el-option>
        <el-option value="必须"></el-option>
        <el-option value="最多"></el-option>
      </el-select>
    </template>
          <template #append>人</template>
        </el-input>
      </el-form-item>
      <el-form-item label="报名时间：" prop="enrollRDate">
        <el-date-picker
          :disabled="title==='设置'"
          class="normal-item"
          type="daterange"
          v-model="rawForm.enrollRDate"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="比赛时间：" prop="matchRDate">
        <el-date-picker
          :disabled="title==='设置'"
          class="normal-item"
          type="daterange"
          v-model="rawForm.matchRDate"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-divider content-position="left"><h4>交易设置</h4></el-divider>
      <el-form-item label="交易品种：" prop="transactionType">
        <el-checkbox-group  v-model="transactionType" style="width: 100%" @change="gptypeChange" :disabled="title==='设置'">
          <el-checkbox :label="item.value" v-for="(item, index) in transactionTypeList"  :key="item.value + index" :disabled="item.disabled">{{item.label}}</el-checkbox>
        </el-checkbox-group>
        <!-- <el-radio-group
          :disabled="title==='设置'" v-model="rawForm.transactionType">
          <el-radio v-for="type in transactionTypeList" :key="type.id" :label="type.id">{{type.courseTypeName}}</el-radio>
        </el-radio-group> -->
      </el-form-item>
      <el-form-item label="基准：" prop="benchmark">
        <el-radio-group
          :disabled="title==='设置'" v-model="rawForm.benchmark">
          <el-radio label="SZ50"></el-radio>
          <el-radio label="HS300"></el-radio>
          <el-radio label="ZZ500"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="初始资金：" prop="initialFunding">
        <el-input
          :disabled="title==='设置'"
          class="normal-item"
          v-model="rawForm.initialFunding"
          placeholder="请输入"
        >
          <template #append>元</template>
        </el-input>
        <div v-if="rawForm.initialFunding > 0 && codeType" class='tag'>
          <p v-if="(types.sb.indexOf(codeType) > -1 )" type="warning" effect="dark">{{ textTip(rawForm.transactionType)  }}</p>
          <p v-if="types.futures.indexOf(codeType) > -1" type="warning" effect="dark">{{ `期货账户初始资金为${rawForm.initialFunding}元` }}</p>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          size="small"
          class="normal-btn"
          type="primary"
          @click="setting('确定')"
          >确定</el-button
        >
        <el-button size="small" class="normal-btn" @click="setting('取消')"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  getCurrentInstance,
  ref,
  readonly,
  watch,
} from "vue";
import { addCompetition, update, findCourseType } from "../apis";
import { createData } from "../datas";
import { userInfo } from "../methods";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "create",
  components: {},
  props: {
    title: {
      type: String,
      default: "创建大赛",
    },
    form: {
      default: {},
    },
  },
  setup(props, { emit }) {
    const data = reactive(createData);
    const types = readonly({
      sb: [1,2,4,5],
      futures: [3,4],
    });
    //提示文字
    const textTip =(val)=>{
      let text=''
      switch(val){
        case 1:
        text= `股票账户初始资金为${data.rawForm.initialFunding}元`
         break ;
         case 2:
        text= `债券账户初始资金为${data.rawForm.initialFunding}元`
         break 
         case 4:
        text= `股票债券账户初始资金为${data.rawForm.initialFunding}元`
         break 
         case 5:
        text= `股票债券账户初始资金为${data.rawForm.initialFunding}元`
         break 
      }
      return text
    }
    const rules = readonly({
      name: [
        //比赛名称
        { required: true, message: "请输入比赛名称", trigger: "blur" },
        { max: 20, message: "长度不要超过20个字符", trigger: "blur" },
      ],
      sponsor: [
        //主办方
        { required: true, message: "请输入主办方名称", trigger: "blur" },
        { max: 20, message: "长度不要超过20个字符", trigger: "blur" },
      ],
      email: [
        { required: true, message: "请输入电子邮箱地址", trigger: "blur" },
        {
          pattern:
            /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/,
          message: "邮箱格式错误",
        },
      ],
      details: [
        //比赛介绍
        { required: true, message: "请输入比赛介绍", trigger: "blur" },
      ],
      transactionType: [
        //交易品种
        // { validator: validatePass, trigger: "blur" },
      ],
      benchmark: [
        //基准
        { required: true, message: "请选择基准", trigger: "blur" },
      ],
      initialFunding: [
        //初始资金
        { required: true, message: "请输入初始资金", trigger: "blur" },
        { pattern: /^[0-9]*[1-9][0-9]*$/, message: "请输入大于零的金额" },
      ],
      enrollRDate: [
        //报名日期
        { required: true, message: "请选择报名日期", trigger: "blur" },
      ],
      matchRDate: [
        //比赛日期
        { required: true, message: "请选择比赛日期", trigger: "blur" },
      ],
      number: [
        //大赛人数
        { required: true, message: "请输入大赛人数", trigger: "blur" },
        { pattern: /^[0-9]*[1-9][0-9]*$/, message: "请输入大于零的人数" },
      ],
      matchType: [
        //比赛类型
        { required: true, message: "请选择比赛类型", trigger: "blur" },
      ],
      groupsNumber: [
        //每组人数
        { required: true, message: "请输入每组人数", trigger: "blur" },
        { pattern: /^[0-9]*[1-9][0-9]*$/, message: "请输入大于零的人数" },
      ],
    });
    const form = ref();

    onMounted(() => {
      data.vueExample = getCurrentInstance();
      // findTransactionType()
    });

    watch(
      props.form,
      (n) => {
        let tempForm = cloneDeep(n);
        tempForm.enrollRDate = tempForm.enrollStartTime ? [tempForm.enrollStartTime, tempForm.enrollEndTime] : null;
        tempForm.matchRDate = tempForm.matchStartTime ? [tempForm.matchStartTime, tempForm.matchEndTime] : null
        tempForm.numberType = tempForm.numberType ? tempForm.numberType : '至少'
        data.transactionType = tempForm.transactionType == 1 ? ['gp'] : tempForm.transactionType == 2 ? ['zq'] : tempForm.transactionType == 3 ? ['qh'] : tempForm.transactionType == 6 ?['wh'] : tempForm.transactionType == 5 ?['gp','zq'] : tempForm.transactionType == 7 ?['wh','gp'] :tempForm.transactionType == 8 ?['wh','zq'] :tempForm.transactionType == 9 ?['wh','qh'] :tempForm.transactionType == 4 ?['gp','zq','qh'] :tempForm.transactionType == 10 ?['wh','gp','zq'] :tempForm.transactionType == 11 ?['wh','gp','qh'] :tempForm.transactionType == 12 ?['wh','qh','zq'] : tempForm.transactionType == 13 ?['wh','gp','zq','qh'] :[] 
        data.rawForm = tempForm;
      },
      {
        deep: true,
        immediate: true,
      }
    );

    const findTransactionType = () => {
      data.loading = true;
      findCourseType()
        .then((res) => {
          const resData = res.data;
          if (resData.status === 0 || resData.code == 200) {
            console.log('resData',resData);
            data.transactionTypeList = resData.data ? resData.data.courseTypes : [];
          } else {
            proxy.$message.error(resData.msg);
          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    }

    const setting = (type) => {
      if (type === "确定") {
        onSubmit();
      }
      if (type === "取消") {
        emit("setting", "");
      }
    };

    const onSubmit = () => {
      const { proxy } = data.vueExample;
      if (data.transactionType.length < 1) {
        proxy.$message.error("请选择交易品种！");
        return
      }
      if (data.codeType == 99) {
        proxy.$message.error("此类型组合暂不支持")
        data.transactionType = []
        return
      }
      if (data.codeType == 991) {
        proxy.$message.error("(股票&期货)组合暂不支持")
        data.transactionType = []
        return
      }
      if (data.codeType == 992) {
        proxy.$message.error("(债券&期货)组合暂不支持")
        data.transactionType = []
        return
      }
      form.value.validate((val) => {
        if (val) {
          data.loading = true;
          const reqData = handleForm(cloneDeep(data.rawForm));
          reqData.transactionType = data.codeType
          reqData.userName = userInfo().userName
          data.rawForm.id ? updateMega(reqData) : addMega(reqData);
        } else {
          proxy.$message.error("请按规则填写所有项目！");
        }
      });
    };

    const updateMega = (reqData) => {
      update(reqData)
        .then((res) => {
          const resData = res.data;
          const { proxy } = data.vueExample;
          if (resData.status === 0 || resData.code == 200) {
            proxy.$message.success(resData.msg);
            emit("setting", "保存");
          } else {
            proxy.$message.error(resData.msg);
          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    const addMega = (reqData) => {
      addCompetition(reqData)
        .then((res) => {
          const resData = res.data;
          const { proxy } = data.vueExample;
          if (resData.status === 0 || resData.code == 200) {
            proxy.$message.success(resData.msg);
          } else {
            proxy.$message.error(resData.msg);
          }
          data.loading = false;
          emit("setting", "保存");
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    const handleForm = (rawForm) => {
      for (let key in rawForm) {
        if (key.indexOf("RDate") > -1) {
          if (key === "enrollRDate") {
            rawForm["enrollStartTime"] = dayjs(rawForm[key][0]).format(
              "YYYY-MM-DD"+" 00:00:00"
            );
            //添加时间：+"23:59:59"  +"00:00:00"
            rawForm["enrollEndTime"] = dayjs(rawForm[key][1]).format(
              "YYYY-MM-DD"+" 23:59:59"
            );
            delete rawForm.enrollRDate;
          }
          if (key === "matchRDate") {
            rawForm["matchStartTime"] = dayjs(rawForm[key][0]).format(
              "YYYY-MM-DD"+" 00:00:00"
            );
            rawForm["matchEndTime"] = dayjs(rawForm[key][1]).format(
              "YYYY-MM-DD"+" 23:59:59"
            );
            delete rawForm.matchRDate;
          }
        }
      }
      rawForm.userName = userInfo().userName;
      rawForm.schoolId = userInfo().schoolId;
      return rawForm;
    };

    const validatePass = (rule, value, callback) => {

      if (data.transactionType.length < 1) {
        console.log("1111");
        callback(new Error('请选择交易品种'))
      } else {
        console.log("1111-22");
        callback()
      }
    }

    const gptypeChange = () => {
      const { proxy } = data.vueExample;
      if (data.transactionType.length == 1) {
        if (data.transactionType.indexOf("gp") != -1) {
          data.codeType = 1
        } else if (data.transactionType.indexOf("zq") != -1) {
          data.codeType = 2
        } else if (data.transactionType.indexOf("qh") != -1) {
          data.codeType = 3
        } else if (data.transactionType.indexOf("wh") != -1) {
          data.codeType = 6
        } 
      } else if (data.transactionType.length == 2) {
        if (data.transactionType.indexOf("gp") != -1 && data.transactionType.indexOf("zq") != -1) {
          data.codeType = 5
        } else if (data.transactionType.indexOf("gp") != -1 && data.transactionType.indexOf("wh") != -1) {
          data.codeType = 7
        } else if (data.transactionType.indexOf("wh") != -1 && data.transactionType.indexOf("zq") != -1) {
          data.codeType = 8
        } else if (data.transactionType.indexOf("wh") != -1 && data.transactionType.indexOf("qh") != -1) {
          data.codeType = 9
        } else if (data.transactionType.indexOf("gp") != -1 && data.transactionType.indexOf("qh") != -1) {
          data.codeType = 991
        } else if (data.transactionType.indexOf("zq") != -1 && data.transactionType.indexOf("qh") != -1) {
          data.codeType = 992
        } else {
          data.codeType = 99
        }
      } else if (data.transactionType.length == 3) {
        if (data.transactionType.indexOf("gp") != -1 && data.transactionType.indexOf("zq") != -1 && data.transactionType.indexOf("qh") != -1) {
          data.codeType = 4
        } else if (data.transactionType.indexOf("wh") != -1 && data.transactionType.indexOf("gp") != -1 && data.transactionType.indexOf("zq") != -1) {
          data.codeType = 10
        } else if (data.transactionType.indexOf("wh") != -1 && data.transactionType.indexOf("gp") != -1 && data.transactionType.indexOf("qh") != -1) {
          data.codeType = 11
        } else if (data.transactionType.indexOf("wh") != -1 && data.transactionType.indexOf("zq") != -1 && data.transactionType.indexOf("qh") != -1) {
          data.codeType = 12
        } else {
          data.codeType = 99
        }
      } else if (data.transactionType.length == 4) {
        if (data.transactionType.indexOf("gp") != -1 && data.transactionType.indexOf("zq") != -1 && data.transactionType.indexOf("qh") != -1 && data.transactionType.indexOf("wh") != -1) {
          data.codeType = 13
        }
      } else if (data.transactionType.length == 0) {
        return;
      }
    };

    return {
      ...toRefs(data),
      rules,
      types,
      setting,
      form,
      textTip,
      gptypeChange,
      validatePass
    };
  },
});
</script>

<style scoped lang="less">
.create {
}
</style> 